<template>
  <div class="my">
    <van-nav-bar title="我的" fixed />
    <div class="my-po">
      <div class="my-bgc"><img src="@/assets/image/my/bgc.png" /></div>
      <div class="my-box">
        <div class="my-head">
          <div class="my-head-left">
            <div class="my-head-left-po">
              <img src="@/assets/image/my/po.png" />
            </div>
            <div class="my-head-left-hello">Hello,</div>
            <div class="my-head-left-name">{{ information.realname }}</div>
          </div>
          <div class="my-head-right">
            <div class="my-head-right-bgc">
              <img src="@/assets/image/my/蒙版组 228@2x.png" />
            </div>
          </div>
        </div>
        <div class="my-bgc-wai">
          <div class="my-bgc-img"><img src="@/assets/image/my/wai.png" /></div>
        </div>
        <ul class="my-ul">
          <li
            class="my-ul-li"
            v-for="(item, index) in list"
            :key="index"
            @click="itemClick(item)"
          >
            <div class="my-ul-li-left">
              <div class="my-ul-li-left-img"><img :src="item.icon" /></div>
              <div style="margin-left: 10px">{{ item.name }}</div>
            </div>
            <div class="my-ul-li-right"><van-icon name="arrow" /></div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "my",
  data() {
    return {
      information: {},
      list: [
        {
          name: "我的关注",
          icon: require("../../assets/image/my/xin.png"),
          id: 0,
        },
        {
          name: "我的合集",
          icon: require("../../assets/image/my/heji.png"),
          id: 1,
        },
        {
          name: "退出登录",
          icon: require("../../assets/image/my/tuichu.png"),
          id: 2,
        },
      ],
    };
  },
  // watch: {
  //   $route: {
  //     handler: function (to, form) {
  //       //当没有token的时候不请求个人信息接口
  //       if (window.localStorage.getItem("token")) this.getMe();
  //     },
  //     // 深度观察监听
  //     deep: true,
  //     immediate: true,
  //   },
  // },
  created() {
    this.getMe();
  },
  mounted() {},
  methods: {
    //获取个人信息
    async getMe() {
      const { data: data } = await this.$http.post("auth/me");
      this.information = data.data;
    },
    //点击
    itemClick(item) {
      if (item.id == 0) this.$router.push("/myfollow");
      if (item.id == 1) this.$router.push("/collection");
      if (item.id == 2) {
        this.$dialog
          .confirm({
            title: "提示",
            message: "您是否要退出登录",
          })
          .then(() => {
            localStorage.removeItem("token");
            localStorage.removeItem("id");
            this.$router.push("/login");
            localStorage.removeItem("projectId");
          })
          .catch(() => {});
      }
    },
  },
};
</script>
<style lang="less" scoped>
.my {
  position: relative;
  height: 100vh;
  .my-po {
    position: relative;
    top: 46px;
  }
  .my-bgc {
    position: relative;
    width: 100%;
    height: 115px;
  }
  .my-box {
    position: relative;
    top: -67px;
    left: 0;
    background-color: #fff;
    border-top-right-radius: 15px;
    border-top-left-radius: 15px;
    padding: 0 23px;
    //头部
    .my-head {
      position: relative;
      display: flex;
      justify-content: space-between;
      .my-head-left {
        position: relative;
        top: -30px;
        left: 0;
        .my-head-left-po {
          width: 60px;
          height: 60px;
          border-radius: 50%;
          overflow: hidden;
          margin-bottom: 11px;
        }
        .my-head-left-hello {
          color: #372b2f;
          font-size: 14px;
        }
        .my-head-left-name {
          font-size: 15px;
          color: #372b2f;
          font-weight: 600;
          margin-top: 9px;
        }
      }
      .my-head-right {
        margin-top: 16px;
        .my-head-right-bgc {
          width: 90px;
          height: 29px;
        }
      }
    }
    .my-bgc-wai {
      margin-bottom: 7px;
      .my-bgc-img {
        width: 100%;
        height: 52px;
      }
    }
    .my-ul {
      font-size: 13px;
      .my-ul-li {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 20px 0;
        border-bottom: 1px solid #ececec;
        &:last-child {
          border-bottom: 0 !important;
        }
        .my-ul-li-left {
          display: flex;
          align-items: center;
          .my-ul-li-left-img {
            width: 18px;
            height: 18px;
          }
        }
        .my-ul-li-right {
        }
      }
    }
  }
}
</style>
