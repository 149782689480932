<template>
  <div
    class="home"
    :style="hashahahahah == true ? windowheight : ''"
    ref="homeScoll"
  >
    <van-nav-bar title="首页" fixed style="z-index: 99999" />
    <!-- 头部 -->
    <div class="home-head">
      <!-- 上 -->
      <div class="home-head-box">
        <div class="home-head-box-img">
          <img src="@/assets/image/home/waijiang.png" />
        </div>
        <div class="home-head-box-btn">
          <div class="home-head-box-btn-dui">
            <el-button type="primary" @click="compareClick">对比</el-button>
          </div>
          <div class="home-head-box-btn-chuang">
            <!-- 创建合集 弹窗     -->
            <el-popover
              id="test2"
              ref="popoverRef"
              placement="bottom-start"
              width="210"
              trigger="click"
              :offset="-40"
            >
              <!-- 按钮 -->
              <el-button
                slot="reference"
                type="primary"
                :disabled="checkList.length == 0 ? true : false"
              >
                创建合集
              </el-button>
              <!-- 按钮 结束 -->
              <!-- 盒子 -->
              <div class="createadd-popover">
                <!-- 内容 -->
                <ul class="createadd-popover-ul">
                  <transition-group tag="ul" name="collection-list">
                    <li
                      class="createadd-popover-ul-li"
                      v-for="(item, index) in collectionList"
                      :key="item.id"
                    >
                      <div class="createadd-popover-ul-li-left">
                        <div class="createadd-popover-ul-li-left-img">
                          <img src="@/assets/image/home/wenjian.png" />
                        </div>
                        <div class="createadd-popover-ul-li-left-text">
                          {{ item.name }}
                        </div>
                      </div>
                      <div
                        class="createadd-popover-ul-li-right"
                        v-if="item.share == 0"
                      >
                        <div
                          class="createadd-popover-ul-li-right-icon-d"
                          @click="collectionAdd(item, index)"
                        >
                          <i class="el-icon-success"></i>
                        </div>
                        <div
                          v-if="item.count == 0"
                          class="createadd-popover-ul-li-right-icon-c"
                          @click="collectionDelete(item, index)"
                        >
                          <i class="el-icon-error"></i>
                        </div>
                      </div>
                    </li>
                  </transition-group>
                  <transition name="collection-list-show">
                    <li
                      v-if="!collectionList.length"
                      style="text-align: center; padding: 10px 0"
                    >
                      暂无数据
                    </li>
                  </transition>
                </ul>
                <!-- 内容 结束 -->
                <!-- 添加分类 -->
                <transition name="category-show">
                  <div class="createadd-popover-bottom" v-if="categoryShow">
                    <div class="createadd-popover-bottom-input">
                      <el-input
                        v-model="addClassification"
                        placeholder="输入分类名称"
                      ></el-input>
                    </div>
                    <div
                      class="createadd-popover-bottom-icon"
                      @click="addCategory()"
                    >
                      <transition name="createadd-popover">
                        <i v-if="addClassification" class="el-icon-success"></i>
                      </transition>
                    </div>
                  </div>
                </transition>
                <div
                  class="createadd-popover-add"
                  @click="categoryShow = !categoryShow"
                >
                  <div>
                    <i class="el-icon-plus"></i>
                  </div>
                  <div style="margin-left: 10px">添加分类</div>
                </div>
              </div>
              <!-- 添加分类 结束 -->
              <!-- 盒子 结束 -->
            </el-popover>
            <!-- 创建合集 弹窗 -->
            <el-popover
              :offset="-20"
              placement="right-start"
              trigger="click"
              style="border-radius: 4px"
            >
              <div slot="reference" class="home-head-box-btn-chuang-img">
                <img src="@/assets/image/home/wenhao.png" />
              </div>
              <div class="home-head-box-btn-chuang-img-buzhou">
                <img src="@/assets/image/home/buzhou.png" />
              </div>
            </el-popover>
          </div>
        </div>
      </div>
      <!-- 上 -->

      <!-- 下 -->
      <div class="home-head-check">
        <div class="home-head-check-left">
          <el-checkbox
            id="test1"
            v-if="collection.list.length"
            class="checkbox-quan"
            :indeterminate="isIndeterminate"
            v-model="checkAll"
            @change="handleCheckAllChange"
            >全选</el-checkbox
          >
          <div class="home-head-check-left-icon" @click="headDrawerReset">
            <i class="el-icon-refresh"></i>
            <span class="home-head-check-left-icon-name">全部</span>
          </div>
        </div>
        <div class="home-head-check-right">
          <el-button
            @click="drawerScreening"
            type="primary"
            style="background: #0e9ba9; color: #fff; border-color: #0e9ba9"
          >
            <div class="create-btn-flex">
              <div class="create-btn-flex-img">
                <img src="@/assets/image/home/shaixuan.png" />
              </div>
              <div>筛选</div>
            </div>
          </el-button>
        </div>
      </div>
      <!-- 下 -->
    </div>
    <!-- 头部 -->
    <!-- 内容 -->
    <div class="foreign-speakers-contents">
      <van-list
        v-model="loading"
        :finished="finished"
        finished-text="没有更多了"
        @load="onLoad"
        :immediate-check="false"
        :offset="500"
      >
        <!-- 暂无数据 -->
        <!-- <div class="none" v-if="!collection.list.length">暂无数据</div> -->
        <!-- 暂无数据 -->
        <van-empty
          v-if="!collection.list.length"
          style="margin: 30% 0"
          description="暂无数据"
        />
        <!-- 列表 -->
        <el-checkbox-group
          v-model="checkList"
          @change="handleCheckedCitiesChange"
        >
          <ul
            class="foreign-speakers-contents-ul"
            v-if="collection.list.length"
          >
            <li
              class="foreign-speakers-contents-li"
              v-for="(item, index) in collection.list"
              :key="index"
            >
              <el-checkbox
                :label="item.id"
                class="checkbox-item checkbox-lalala"
              >
                <div style="line-height: 0px"><br /></div>
              </el-checkbox>

              <!-- 关注 取消关注 -->
              <div
                class="guanzhu-quxiao"
                @click.stop.prevent="expertFollowClick(item)"
              >
                <div
                  class="foreinger-list-item-contents-right-box"
                  v-if="item.is_follow == 0"
                >
                  <div class="foreinger-list-item-contents-right-box-img">
                    <img src="@/assets/image/home/guanzhu.png" />
                  </div>
                  <div class="foreinger-list-item-contents-right-box-text">
                    关注
                  </div>
                </div>
                <div
                  class="foreinger-list-item-contents-right-box"
                  v-if="item.is_follow == 1"
                >
                  <div class="foreinger-list-item-contents-right-box-img">
                    <img src="@/assets/image/home/quxiaoguanzhu.png" />
                  </div>
                  <div class="foreinger-list-item-contents-right-box-text">
                    取消关注
                  </div>
                </div>
              </div>
              <!-- 关注 取消关注 -->
              <!-- 标题 -->
              <div class="biaoti">
                <!-- 头像 -->
                <div class="foreign-speakers-contents-li-img-box">
                  <div class="foreign-speakers-contents-li-img">
                    <img :src="item.photo" />
                  </div>
                </div>
                <div class="foreign-speakers-contents-li-name">
                  {{ item.name }}
                </div>
              </div>
              <!-- 标签 -->
              <div
                class="foreign-speakers-contents-li-biaoqian"
                v-if="item.tags"
              >
                <span class="span-biaoqian" style="background-color: #bee3df">
                  {{ item.tags.split(",")[0] }}
                </span>
                <span style="background-color: #f3d0d0" class="span-biaoqian">
                  {{ item.tags.split(",")[1] }}
                </span>
                <span class="span-biaoqian" style="background-color: #d0e5f9">
                  {{ item.tags.split(",")[2] }}
                </span>
              </div>
              <!-- 国家 -->
              <div class="foreign-speakers-contents-li-country">
                <span class="country-one"></span>
                <span class="country-two">国家：</span>
                <span class="country-three">{{ item.country }}</span>
              </div>
              <!-- 任职 -->
              <div class="foreign-speakers-contents-li-appointments">
                <div class="appointments-dis">
                  <span class="appointments-one"></span>
                  <span class="appointments-two">就职机构及任职：</span>
                </div>
                <div class="appointments-three">{{ item.institution }}</div>
              </div>
              <!-- 折叠 -->
              <el-collapse accordion @change="handleChange">
                <el-collapse-item>
                  <!-- 折叠内容 -->
                  <div class="foreignSpeakersDetails-contents-bottom">
                    <!-- 就职机构 -->
                    <div
                      class="foreignSpeakersDetails-contents-bottom-position"
                      v-if="item.institution"
                    >
                      <div
                        class="foreignSpeakersDetails-contents-bottom-position-name"
                      >
                        就职机构及职位
                      </div>
                      <div class="none-zhe"></div>
                      <div
                        class="foreignSpeakersDetails-contents-bottom-position-text"
                      >
                        {{ item.institution }}
                      </div>
                    </div>
                    <!-- 擅长领域 -->
                    <div
                      class="foreignSpeakersDetails-contents-bottom-position"
                      v-if="item.areas_of_expertise"
                    >
                      <div
                        class="foreignSpeakersDetails-contents-bottom-position-name top"
                      >
                        擅长领域
                      </div>
                      <div
                        class="foreignSpeakersDetails-contents-bottom-position-text"
                      >
                        {{ item.areas_of_expertise }}
                      </div>
                    </div>
                    <!-- 学协会及任职 -->
                    <div
                      class="foreignSpeakersDetails-contents-bottom-position"
                      v-if="item.association"
                    >
                      <div
                        class="foreignSpeakersDetails-contents-bottom-position-name top"
                      >
                        学协会及任职
                      </div>
                      <div
                        class="foreignSpeakersDetails-contents-bottom-position-text"
                      >
                        {{ item.association }}
                      </div>
                    </div>
                    <!-- 演讲研究赞助商 -->
                    <div
                      class="foreignSpeakersDetails-contents-bottom-position"
                      v-if="item.research_sponsor"
                    >
                      <div
                        class="foreignSpeakersDetails-contents-bottom-position-name top"
                      >
                        演讲研究赞助商
                      </div>
                      <div
                        class="foreignSpeakersDetails-contents-bottom-position-text"
                      >
                        {{ item.research_sponsor }}
                      </div>
                    </div>
                    <!-- 会议演讲及题目 -->
                    <div
                      class="foreignSpeakersDetails-contents-bottom-position"
                      v-if="item.presenting_meeting"
                    >
                      <div
                        class="foreignSpeakersDetails-contents-bottom-position-name top"
                      >
                        会议演讲及题目
                      </div>
                      <div
                        class="foreignSpeakersDetails-contents-bottom-position-text"
                      >
                        {{ item.presenting_meeting }}
                      </div>
                    </div>
                    <!-- 期刊及任职及影响因子 -->
                    <div
                      class="foreignSpeakersDetails-contents-bottom-position"
                      v-if="item.journal_position"
                    >
                      <div
                        class="foreignSpeakersDetails-contents-bottom-position-name top"
                      >
                        期刊及任职及影响因子
                      </div>
                      <div
                        class="foreignSpeakersDetails-contents-bottom-position-text"
                      >
                        {{ item.journal_position }}
                      </div>
                    </div>
                  </div>
                  <!-- 折叠内容 -->
                </el-collapse-item>
              </el-collapse>
              <!-- 折叠 -->
            </li>
          </ul>
        </el-checkbox-group>
      </van-list>
    </div>
    <!-- 内容 -->
    <!-- 底部 -->
    <div class="home-p-m" id="test3" v-if="hashahahahah">
      <van-icon name="friends-o" />
    </div>
    <!-- 底部 -->
    <!-- 筛选弹窗 -->
    <van-popup
      :lock-scroll="false"
      v-model="drawerShow"
      position="bottom"
      :style="{ height: '78%' }"
    >
      <h4 class="popup-tiaojian">筛选条件</h4>
      <van-cell-group>
        <van-field
          v-model="screening.screeningNmae"
          label="讲者姓名"
          placeholder="请输入讲者姓名"
        />
        <van-field
          v-model="screening.screeningCity"
          label="城市/国家"
          placeholder="请输入城市/国家"
        />
        <van-field
          v-model="screening.screeningTimelag"
          label="时区时差"
          placeholder="请输入时区时差"
        />
        <van-field
          v-model="screening.screeningMedical"
          label="医疗领域"
          placeholder="请输入医疗领域"
        />
        <van-field
          v-model="screening.screeningExpertise"
          label="擅长领域"
          placeholder="请输入擅长领域"
        />
        <van-field
          v-model="screening.screeningPresentations"
          rows="2"
          autosize
          label="会议演讲题目"
          type="textarea"
          placeholder="请输入会议演讲题目"
        />
        <van-field
          v-model="screening.screeningAppointments"
          rows="2"
          autosize
          label="学协会及任职"
          type="textarea"
          placeholder="请输入学协会及任职"
        />
        <van-field
          v-model="screening.screeningInstitutions"
          rows="2"
          autosize
          label="机构/职位"
          type="textarea"
          placeholder="请输入结构/职位"
        />
        <van-field
          v-model="screening.screeningSponsorship"
          rows="2"
          autosize
          label="赞助商"
          type="textarea"
          placeholder="请输入赞助商"
        />
        <van-field
          v-model="screening.screeningImpact"
          rows="2"
          autosize
          label="期刊/影响因子"
          type="textarea"
          placeholder="请输入期刊/影响因子"
        />
      </van-cell-group>
      <div style="margin: 16px">
        <van-button
          style="margin-bottom: 13px"
          type="primary"
          block
          color="#0E9BA9"
          @click="drawerSearch"
        >
          查询
        </van-button>
        <van-button type="info" block color="#2563EB" @click="drawerReset"
          >重置</van-button
        >
      </div>
    </van-popup>
    <!-- 筛选弹窗 -->
  </div>
</template>
<script>
import wx from "weixin-js-sdk";
export default {
  name: "home",
  data() {
    return {
      windowheight: {
        //高度等于屏幕高度
        height: document.documentElement.clientHeight + "px",
        overflow: "hidden",
      },
      input: "",
      selectLike: false,
      hashahahahah: false, //新手引导
      //*新手指引
      stepss: [
        {
          element: "#test1",
          popover: {
            title: "说明：",
            description: "第一步：选择外籍讲者",
            position: "right",
          },
        },
        {
          element: "#test2",
          popover: {
            title: "说明：",
            description: "第二步：创建合集分类",
            position: "left",
          },
        },
        {
          element: "#test3",
          popover: {
            title: "说明：",
            description:
              "第三步：选择合集分类，创建完成的合集在【我的合集】查看",
            position: "left-bottom",
          },
          onDeselected: () => {
            this.hashahahahah = false;
          }, //跳过 完成
        },
      ],
      drawerShow: false,
      isIndeterminate: false, // 全选的标记
      checkAll: false, // 全选
      checkList: [], // 已经选中的数据
      checkedKeyLists: [], // key的list数据
      SelectAllApiList: [], //全选接口
      value: "",
      loading: false, //加载状态不需要再触发onLoad
      finished: false, //完成加载不需要触发onLoad
      page: 1, //第几页
      limit: 8, //一页几个
      shareName: "",
      collection: { list: [] }, //合集数据
      ShareDomainName: "https://speaker.mobile.netloop.cn/home",
      aaaaaa: [],
      lastClickTime: 0,
      isFold: "展开",
      collectionList: [],
      categoryShow: false,
      addClassification: "", //添加分类
      screening: {
        screeningNmae: "",
        screeningCity: "",
        screeningTimelag: "",
        screeningMedical: "",
        screeningExpertise: "",
        screeningPresentations: "",
        screeningAppointments: "",
        screeningInstitutions: "",
        screeningSponsorship: "",
        screeningImpact: "",
      },
      oldScrollTop: 0, //记录上一次滚动结束后的滚动距离
      scrollTop: 0, // 记录当前的滚动距离
    };
  },
  watch: {
    scrollTop(newValue, oldValue) {
      setTimeout(() => {
        if (newValue == window.scrollY) {
          //延时执行后当newValue等于window.scrollY，代表滚动结束
          this.oldScrollTop = newValue; //每次滚动结束后都要给oldScrollTop赋值
          console.log("滚动结束");
        }
      }, 20); //必须使用延时器，否则每次newValue和window.scrollY都相等，无法判断，20ms刚好大于watch的侦听周期，故延时20ms
      if (this.oldScrollTop == oldValue) {
        //每次滚动开始时oldScrollTop与oldValue相等
        console.log("滚动开始");
      }
    },
  },
  created() {
    this.getForeignSpeakerList(); //列表
    this.getSelectAllApi(); //获取全选 接口
    this.getCollectionList(); //合集列表
    // this.WXShare();
  },
  mounted() {
    this.handleScroll();
  },
  methods: {
    //新手引导
    getguide() {
      this.hashahahahah = true;
      this.$driver.defineSteps(this.stepss);
      this.$driver.start();
    },
    //判断是否创建合集 展示 新手指引
    async judgementNewUsers() {
      const { data: data } = await this.$http.get("speaker/collection/isFirst");
      if (data.data.is_first != 0) {
        this.getguide();
      }
    },
    //问号
    ceshiClick() {
      console.log("点击");
    },
    //全选
    handleCheckAllChange(value) {
      this.checkedKeyLists = [...new Set(this.checkedKeyLists)];
      this.checkList = value ? this.checkedKeyLists : [];
      this.isIndeterminate = false;
    },
    //单选
    handleCheckedCitiesChange(value) {
      let checkedCount = [...new Set(value)].length;
      let aaa = [...new Set(this.checkedKeyLists)].length;
      this.checkAll = checkedCount === aaa;
      this.isIndeterminate =
        checkedCount > 0 &&
        checkedCount < [...new Set(this.checkedKeyLists)].length;
    },
    //列表
    async getForeignSpeakerList() {
      const { data: data } = await this.$http.post("speaker/list", {
        page: this.page,
        limit: this.limit,
        name: this.screening.screeningNmae, //讲者姓名
        country: this.screening.screeningCity, //国家
        time_zone: this.screening.screeningTimelag, //时区
        medical_category: this.screening.screeningMedical, //医疗领域
        areas_of_expertise: this.screening.screeningExpertise, //擅长领域
        presenting_meeting: this.screening.screeningPresentations, //会议演讲及题目
        association: this.screening.screeningAppointments, //学协会及任职
        institution: this.screening.screeningInstitutions, //就只机构/机构职位
        research_sponsor: this.screening.screeningSponsorship, //演讲研究赞助商
        journal_position: this.screening.screeningImpact, //筛选-期刊及任职及影响因子
        global_search: this.input, //全局搜索
      });
      this.collection = data.data;
      this.shareName = data.data.collection_name;
      this.$toast.clear();
      //新手引导
      setTimeout(() => {
        this.judgementNewUsers();
      }, 100);
    },
    //全选请求接口
    async getSelectAllApi() {
      const { data: data } = await this.$http.post("speaker/lists", {
        name: this.screening.screeningNmae, //讲者姓名
        country: this.screening.screeningCity, //国家
        time_zone: this.screening.screeningTimelag, //时区
        medical_category: this.screening.screeningMedical, //医疗领域
        areas_of_expertise: this.screening.screeningExpertise, //擅长领域
        presenting_meeting: this.screening.screeningPresentations, //会议演讲及题目
        association: this.screening.screeningAppointments, //学协会及任职
        institution: this.screening.screeningInstitutions, //就只机构/机构职位
        research_sponsor: this.screening.screeningSponsorship, //演讲研究赞助商
        journal_position: this.screening.screeningImpact, //筛选-期刊及任职及影响因子
        global_search: this.input, //全局搜索
      });
      this.SelectAllApiList = data.data;
      // 数据
      this.init();
      //点击全选的时候 请求数据 是否是全选
      if (this.checkAll) {
        this.checkedKeyLists = [...new Set(this.checkedKeyLists)];
        this.checkList = this.checkedKeyLists;
      }
    },
    //筛选key的数据 全选请求接口
    init() {
      this.checkedKeyLists = [];
      this.checkList = [];
      this.SelectAllApiList.forEach((el) => {
        this.checkedKeyLists.push(el.id);
      });
    },
    //展开折叠事件
    handleChange(val) {},
    //上拉加载列表
    async getLoadingForeignSpeakerList() {
      const { data: data } = await this.$http.post("speaker/list", {
        name: this.value,
        page: this.page,
        limit: this.limit,
        country: this.screening.screeningCity, //国家
        time_zone: this.screening.screeningTimelag, //时区
        medical_category: this.screening.screeningMedical, //医疗领域
        areas_of_expertise: this.screening.screeningExpertise, //擅长领域
        presenting_meeting: this.screening.screeningPresentations, //会议演讲及题目
        association: this.screening.screeningAppointments, //学协会及任职
        institution: this.screening.screeningInstitutions, //就只机构/机构职位
        research_sponsor: this.screening.screeningSponsorship, //演讲研究赞助商
        journal_position: this.screening.screeningImpact, //筛选-期刊及任职及影响因子
        global_search: this.input, //全局搜索
      });

      this.loading = false; //加载状态不需要再触发onLoad
      this.collection.list.push(...data.data.list);
      if (this.collection.list.length >= data.data.total) this.finished = true; //完成加载不需要触发onLoad
      //3上拉加载
    },
    //上拉加载
    async onLoad() {
      this.page++;
      this.getLoadingForeignSpeakerList();
    },
    //对比
    compareClick() {
      if (this.checkList.length == 0) {
        this.$toast("请选择对比数据");
        return;
      }
      if (this.checkList.length > 5) {
        this.$toast("只能选择5个进行比对");
        return;
      }
      //少于5个
      let list = JSON.stringify(this.checkList);
      this.$router.push({
        path: "/compare",
        query: {
          list,
        },
      });
    },
    //筛选
    drawerScreening() {
      this.drawerShow = true;
    },
    //全部 刷新
    headDrawerReset() {
      this.$toast.loading({
        message: "加载中...",
        forbidClick: true,
        loadingType: "spinner",
      });
      this.page = 1;
      this.input = "";
      this.screening.screeningNmae = "";
      this.screening.screeningCity = "";
      this.screening.screeningTimelag = "";
      this.screening.screeningMedical = "";
      this.screening.screeningExpertise = "";
      this.screening.screeningPresentations = "";
      this.screening.screeningAppointments = "";
      this.screening.screeningInstitutions = "";
      this.screening.screeningSponsorship = "";
      this.screening.screeningImpact = "";
      this.checkedKeyLists = [];
      this.checkList = [];
      this.checkAll = false;
      setTimeout(() => {
        this.getForeignSpeakerList();
        this.getSelectAllApi(); //全选
      }, 500);
    },
    //查询
    drawerSearch() {
      this.$toast.loading({
        message: "加载中...",
        forbidClick: true,
        loadingType: "spinner",
      });
      this.input = "";
      this.checkedKeyLists = [];
      this.checkList = [];
      this.checkAll = false;
      setTimeout(() => {
        this.getForeignSpeakerList();
        this.getSelectAllApi(); //全选
        this.drawerShow = false;
      }, 500);
    },
    //筛选弹窗搜索重置
    drawerReset() {
      this.$toast.loading({
        message: "加载中...",
        forbidClick: true,
        loadingType: "spinner",
      });
      this.screening.screeningNmae = "";
      this.screening.screeningCity = "";
      this.screening.screeningTimelag = "";
      this.screening.screeningMedical = "";
      this.screening.screeningExpertise = "";
      this.screening.screeningPresentations = "";
      this.screening.screeningAppointments = "";
      this.screening.screeningInstitutions = "";
      this.screening.screeningSponsorship = "";
      this.screening.screeningImpact = "";
      this.checkedKeyLists = [];
      this.checkList = [];
      this.checkAll = false;
      this.getForeignSpeakerList();
      this.getSelectAllApi(); //全选
      // this.drawerShow = false
    },
    //关注 取消关注 点击
    expertFollowClick(item) {
      let status = 0;
      if (item.is_follow == 0) {
        status = 1;
      }
      if (item.is_follow == 1) {
        status = 0;
      }
      if (status == 0) {
        this.$dialog
          .confirm({
            title: "提示",
            message: "您是否要取消关注？",
          })
          .then(() => {
            this.getuserFollow(item.id, status);
          })
          .catch(() => {});
      } else {
        this.getuserFollow(item.id, status);
      }
    },
    //关注 取消关注 接口
    async getuserFollow(info_id, status) {
      const { data: data } = await this.$http.post("speaker/follow", {
        info_id,
        type_id: 8,
        status,
      });
      // this.getForeignSpeakerList();
      this.$toast(data.message);
      location.reload();
    },
    handleScroll() {
      window.addEventListener("scroll", () => {
        this.scrollTop = window.scrollY;
      });
    },
    //合集列表
    async getCollectionList() {
      const { data: data } = await this.$http.get("speaker/collection/cate");
      this.collectionList = data.data;
    },
    //存放合集
    async collectionAdd(item) {
      let list = [...new Set(this.checkList)]; //数组去重
      const { data: data } = await this.$http.post("speaker/put/speakers", {
        collection_id: item.id,
        speaker_id: list,
      });
      this.getCollectionList();
      this.$toast(data.message);
      this.$router.push("/collection");
    },
    //删除合集
    async collectionDelete(item) {
      const { data: data } = await this.$http.post(
        "speaker/collection/delete",
        { id: item.id }
      );
      this.getCollectionList();
      this.$toast(data.message);
    },
    //添加集合
    async addCategory() {
      const { data: data } = await this.$http.post("speaker/guest/collection", {
        id: "", //修改时需要
        name: this.addClassification,
        photo: "", //封面图
      });
      this.getCollectionList();
      this.addClassification = "";
      this.$toast(data.message);
    },
    //微信分享
    async WXShare() {
      let that = this;
      const { data: data } = await that.$http.get("wechat/config", {
        params: {
          url: window.location.href, //that.ShareDomainName
        },
      });
      if (data.code != 200) {
        return;
      }
      console.log(data.data);
      wx.config(data.data);
      wx.ready(() => {
        let shareData = {
          title: that.shareName,
          desc: "权威、高效、便捷!",
          link: data.data.url,
          imgUrl:
            "https://resources.gongshicloud.com/default%2Fimage%2Fshare_collection_image2.png",
        };
        wx.updateAppMessageShareData(shareData);
        wx.updateTimelineShareData(shareData);
      });
      wx.error((res) => {
        // alert("分享失败");
      });
    },
  },
};
</script>
<style lang="less" scoped>
.home {
  position: relative;
  .home-p-m {
    position: absolute;
    font-size: 23px;
    color: #646566;
    top: calc(100vh - 46px);
    right: 57px;
    // right: 200px;
    z-index: 999999;
  }
  .home-head {
    position: relative;
    margin-top: 46px;

    .home-head-box {
      padding: 10px 15px;
      box-shadow: 0px 0px 50px 1px #efefef;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .home-head-box-img {
        width: 102px;
        height: 33px;
      }
      .home-head-box-btn {
        display: flex;
        align-items: center;
        .home-head-box-btn-dui {
          position: relative;
        }
        .home-head-box-btn-chuang {
          margin-left: 10px;
          margin-right: 20px;
        }
        .home-head-box-btn-chuang-img {
          position: absolute;
          top: 5px;
          right: 15px;
          width: 14px;
          height: 14px;
          z-index: 999;
        }
      }
    }
    /deep/ .el-button {
      padding: 8px 16px !important;
    }
    .home-head-check {
      margin: 15px 15px;
      margin-bottom: 0px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .home-head-check-left {
        display: flex;
        align-items: center;
        .home-head-check-left-icon {
          margin-left: 20px;
          color: #2563eb;
          .home-head-check-left-icon-name {
            margin-left: 4px;
            font-size: 13px;
          }
        }
      }
      .home-head-check-right {
        .create-btn-flex {
          display: flex;
          align-items: center;
          .create-btn-flex-img {
            width: 11px;
            height: 11px;
            margin-right: 5px;
            margin-top: -2px;
          }
        }
      }
    }
  }
  //标签
  .foreign-speakers-contents-li-biaoqian {
    display: flex;
    align-items: center;
    margin-top: 14px;
    font-size: 13px;
    color: #212529;
    font-weight: 600;
    .span-biaoqian {
      padding: 6px 12px;
      border-radius: 3px;
      margin-right: 12px;
    }
  }
  //暂无数据
  .none {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 15px;
    color: #1e1e1e;
  }
  .popup-tiaojian {
    padding: 16px;
  }
  //搜索
  .foreign-speakers-seach {
    position: fixed;
    width: 100%;
    padding: 0 3px;
    background-color: #fff;
    z-index: 99999999999999999;
  }
  .foreign-speakers-baocun {
    position: relative;
    top: 62px;
    left: 0;
    padding-left: 15px;
    /deep/.van-button {
      height: 32px;
    }
  }

  //内容
  .foreign-speakers-contents {
    width: 100%;
    // height: 100vh;
    // background-color: #f6f5fa;
    height: calc(100% - 46px);
    background-color: #fff;
    margin-bottom: 8px;

    .foreign-speakers-contents-ul {
      position: relative;
      // padding: 70px 15px 0 15px;
      padding: 15px 15px 0 15px;
      margin-bottom: 20px;

      //奇数
      .foreign-speakers-contents-li:nth-child(odd) {
        //标题
        .foreign-speakers-contents-li-name {
          flex: 1;
          padding: 9px 10px;
          display: flex;
          align-items: center;
          border-radius: 17px;
          font-size: 16px;
          background-color: #d5e3fe;
          color: #437beb;
        }
      }
      //偶数
      .foreign-speakers-contents-li:nth-child(even) {
        //标题
        .foreign-speakers-contents-li-name {
          flex: 1;
          padding: 9px 10px;
          display: flex;
          align-items: center;
          border-radius: 17px;
          font-size: 16px;
          background-color: #f3daf7;
          color: #a739bb;
        }
      }
      //每一个模块
      .foreign-speakers-contents-li {
        position: relative;
        // padding: 12px 10px;
        padding: 14px 10px;
        margin-bottom: 15px;
        border-radius: 8px;
        background-color: #ffffff;
        box-shadow: 0px 3px 8px #e0dfff;
        &:last-child {
          margin-bottom: 0;
        }
        .foreign-speakers-contents-li-guanzhu {
          position: absolute;
          top: -25px;
          display: flex;
          justify-content: flex-end;
        }
        .guanzhu-quxiao {
          width: 80px;
          position: relative;
          top: -22px;
          right: -78%;
          // position: relative;
          // top: -24px;
          // right: 0;
          display: flex;
          align-items: center;
          justify-content: flex-end;
          font-size: 13px;
          color: #1e1e1e;
          // margin-bottom: 10px;
          .foreinger-list-item-contents-right-box {
            display: flex;
            align-items: center;
            .foreinger-list-item-contents-right-box-img {
              margin-top: 4px;
              margin-right: 3px;
              width: 16px;
              height: 16px;
            }
          }
        }
        //国家
        .foreign-speakers-contents-li-country {
          display: flex;
          align-items: center;
          margin-top: 14px;
          font-size: 15px;
          color: #1e1e1e;
          .country-one {
            display: block;
            width: 10px;
            height: 4px;
            background-color: #4584f2;
            margin-right: 6px;
          }
          .country-two {
            width: 46px;
            font-weight: 600;
          }
          .country-three {
            flex: 1;
          }
        }
        //任职
        .foreign-speakers-contents-li-appointments {
          margin: 18px 0 15px 0;
          font-size: 15px;
          color: #1e1e1e;
          .appointments-dis {
            display: flex;
            align-items: center;
            .appointments-one {
              display: block;
              width: 10px;
              height: 4px;
              background-color: #23a519;
              margin-right: 6px;
            }
            .appointments-two {
              font-weight: 600;
            }
          }
          .appointments-three {
            margin-top: 10px;
            line-height: 25px;
          }
        }
      }
    }
  }
  .recommend-box {
    // margin-top: 5px;
    .recommend-box-on {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 14px;
      .recommend-box-on-text-none {
        font-size: 13px;
        color: #fff;
        background-color: #4059fb;
        // padding: 7px 14px;
        border-radius: 4px;
      }
      .recommend-box-on-text {
        font-size: 13px;
        color: #fff;
        background-color: #4059fb;
        padding: 7px 14px;
        border-radius: 4px;
      }
      .recommend-box-on-box {
        display: flex;
        align-items: center;
        .recommend-box-on-box-zan {
          margin-right: 4px;
          .recommend-box-on-box-zan-img {
            width: 15px;
            height: 13px;
            img {
              width: 100%;
              height: 100%;
            }
          }
        }
        .recommend-box-on-box-num {
          font-size: 13px;
          color: #8e96b4;
          margin-bottom: -4px;
        }
      }
    }
    .recommend-box-liyou {
      margin-bottom: 20px;
      font-size: 15px;
      display: flex;
      .recommend-box-liyou-name {
        width: 84px;
        color: #252525;
        font-weight: 600;
      }
      .recommend-box-liyou-text {
        flex: 1;
        color: #252525;
      }
    }
  }

  .biaoti {
    display: flex;
    //头像
    .foreign-speakers-contents-li-img-box {
      width: 36px;
      margin-right: 8px;
      .foreign-speakers-contents-li-img {
        width: 36px;
        height: 36px;
        overflow: hidden;
        border-radius: 50%;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }

  /deep/.el-collapse {
    border: none !important;
  }
  /deep/ .el-collapse-item__header {
    border-bottom: none !important;
    height: 30px !important;
    line-height: 30px !important;
  }
  /deep/ .el-collapse-item__wrap {
    border-bottom: none !important;
  }
  // 折叠内容
  .foreignSpeakersDetails-contents-bottom {
    position: relative;
    top: -20px;
    padding: 22px 15px 0 15px;
    width: 100%;
    background-color: #fff;

    .none-zhe {
      color: #999999;
      font-size: 13px;
    }

    .foreignSpeakersDetails-contents-bottom-position {
      .top {
        // margin-top: 20px;
        margin-top: 10px;
      }
      .foreignSpeakersDetails-contents-bottom-position-name {
        color: #000;
        font-weight: 600;
        // margin-bottom: 10px;
        margin-bottom: 5px;
      }

      .foreignSpeakersDetails-contents-bottom-position-text {
        color: #999999;
        font-size: 13px;
        line-height: 25px;
        padding-bottom: 10px;
        border-bottom: 1px solid #f5f6f8;
      }
      .foreignSpeakersDetails-contents-bottom-position-Source {
        color: #3479a7;
        font-size: 13px;
        line-height: 25px;
      }
    }
  }
  /deep/ .el-collapse-item__content {
    padding-bottom: 0 !important;
  }

  /deep/ .el-collapse-item__header {
    justify-content: flex-end !important;
    align-items: center !important;
    font-size: 12px !important;
  }
  /deep/ .el-collapse-item__arrow {
    margin: 0 8px 0 5px !important;
    font-size: 14px !important;
  }
}
.el-collapse-item__header {
  border-bottom: none !important;
  height: 30px !important;
  line-height: 30px !important;
}
.el-collapse {
  border-top: none !important;
}

//创建合集

.createadd-popover {
  overflow: hidden;
  ::-webkit-scrollbar {
    display: none;
  }
  .createadd-popover-ul {
    margin: 5px 0;
    max-height: 275px;
    overflow: hidden;
    overflow-y: scroll;
    padding: 0 6px;
    .createadd-popover-ul-li {
      // padding: 5px 10px;
      padding: 5px 0;
      transition: 0.3s all;
      display: flex;
      align-items: center;
      justify-content: space-between;
      // margin-bottom: 20px;
      transition: 0.3 all;
      color: #212529;
      &:last-child {
        margin-bottom: 0px;
      }
      // &:hover {
      //   border-radius: 6px;
      //   background-color: #eeeeee;
      //   cursor: pointer;
      // }
      .createadd-popover-ul-li-left {
        display: flex;
        align-items: center;
        .createadd-popover-ul-li-left-img {
          width: 14px;
          height: 12px;
          margin-right: 15px;
          margin-bottom: 5px;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .createadd-popover-ul-li-left-text {
          // width: 90px;
          width: 60px;
          padding: 0 5px;
          font-size: 13px;
          color: #212529;
        }
      }
      .createadd-popover-ul-li-right {
        display: flex;
        align-items: center;
        font-size: 18px;
        .createadd-popover-ul-li-right-icon-d {
          // margin-right: 12px;
          margin-right: 2px;
          color: #68bf7b;
          cursor: pointer;
        }
        .createadd-popover-ul-li-right-icon-c {
          color: #919191;
          cursor: pointer;
        }
      }
    }
  }
  .createadd-popover-bottom {
    box-shadow: 0px -1px 6px rgba(38, 129, 233, 0.16);
    padding: 8px 18px;
    display: flex;
    align-items: center;
    overflow: hidden;
    .createadd-popover-bottom-input {
      width: 132px;
      margin-right: 6px;
    }
    .createadd-popover-bottom-icon {
      cursor: pointer;
      font-size: 22px;
      color: #4e7be5;
    }
  }
  .createadd-popover-add {
    cursor: pointer;
    height: 40px;
    background-color: #437beb;
    color: #fff;
    font-size: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    // border-radius: 0px 0px 8px 8px;
  }
  .loading-cen {
    font-size: 13px;
    text-align: center;
    margin-top: 15px;
  }

  // 添加input动画
  //显示
  .createadd-popover-enter-active {
    animation: swirl-in-fwd 0.5s ease-out both;
  }
  @keyframes swirl-in-fwd {
    0% {
      transform: rotate(-540deg) scale(0);
      opacity: 0;
    }
    100% {
      transform: rotate(0) scale(1);
      opacity: 1;
    }
  }
  //隐藏
  .createadd-popover-leave-to {
    animation: swirl-out-bck 0.5s ease-in both;
  }
  @keyframes swirl-out-bck {
    0% {
      transform: rotate(0) scale(1);
      opacity: 1;
    }
    100% {
      transform: rotate(-540deg) scale(0);
      opacity: 0;
    }
  }
}
.home-head-box-btn-chuang-img-buzhou {
  // width: 278px;
  // height: 203px;
  width: 248px;
  height: 127px;
}
/deep/.van-popup {
  overflow: scroll !important;
}
</style>
<!-- 步骤下一步样式 -->
<style>
[class*="driver-close-btn"] {
  visibility: hidden;
}
[class*="driver-prev-btn"] {
  visibility: hidden;
}
div#driver-popover-item .driver-popover-footer button {
  background-color: #409eff !important;
  border-color: #409eff !important;
  color: #fff !important;
  text-shadow: none !important; /* 取消原来的text阴影，不然会有文 字重叠的感觉 */
  border: none !important; /* 取消原来它自己按钮设置的border */
  line-height: 1 !important;
  border-radius: 4px !important;
  padding: 8px 10px !important;
}
</style>
