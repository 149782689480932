<template>
  <div class="login">
    <div class="login-bgc">
      <img src="@/assets/image/entrance/login-bgc.png" />
    </div>
    <div class="login-box">
      <el-form :model="ruleForm" :rules="rules" ref="ruleForm">
        <el-form-item prop="username">
          <el-input
            placeholder="请输入手机号"
            v-model="ruleForm.username"
          ></el-input>
        </el-form-item>

        <el-form-item prop="password">
          <el-input
            placeholder="请输入密码"
            v-model="ruleForm.password"
            show-password
          ></el-input>
        </el-form-item>
        <div class="forget" @click="$router.push('/forgetPwd')">忘记密码？</div>
        <div class="login-btn">
          <el-button
            style="
              background: #0e9ba9;
              color: #fff;
              width: 100%;
              border: #0e9ba9;
            "
            type="primary"
            @click="submitForm('ruleForm')"
            v-prevent="2000"
            >登录</el-button
          >
        </div>
        <div class="register">
          <span>没有账号？</span
          ><span class="register-text" @click="$router.push('/registered')"
            >去注册</span
          >
        </div>
      </el-form>

      <div class="weixin">
        <div class="weixin-box">
          <span class="weixin-box-gang"></span>
          <span class="weixin-box-name">其他登录方式</span>
          <span class="weixin-box-gang"></span>
        </div>
        <div class="weixin-img" @click="wxgetMobileCode">
          <img src="@/assets/image/entrance/weixin.png" />
        </div>
      </div>
      <!--  -->
      <van-dialog
        v-model="bindShow"
        title="绑定手机号"
        show-cancel-button
        :before-close="onBeforeClose"
      >
        <!-- @confirm="bindShowSubmission" -->
        <van-field
          v-model="ruleForm.phone"
          label="手机号"
          placeholder="请输入手机号"
        />
        <van-field
          v-model="inputco"
          center
          clearable
          label="验证码"
          placeholder="请输入验证码"
        >
          <template #button>
            <get-code :ruleForm="ruleForm" :type="'INFO'" />
          </template>
        </van-field>
        <van-field
          v-model="inputgo"
          label="公司名称"
          placeholder="请输入公司名称"
        />
        <div class="pw-text">密码默认123456</div>
      </van-dialog>
      <!--  -->
    </div>
  </div>
</template>
<script>
import getCode from "@/components/getCode/index.vue";
export default {
  name: "login",
  components: {
    getCode,
  },
  data() {
    return {
      open_id: "",
      inputpo: "",
      inputco: "",
      inputgo: "",
      bindShow: false, //--------
      ruleForm: {
        phone: "",
        username: "",
        password: "",
      },
      rules: {
        username: [
          { required: true, message: "请输入手机号", trigger: "blur" },
        ],
        password: [{ required: true, message: "请输密码", trigger: "blur" }],
      },
    };
  },
  created() {
    if (
      this.$route.query.projectId != "" &&
      this.$route.query.projectId != undefined &&
      this.$route.query.projectId != null
    ) {
      localStorage.setItem("projectId", this.$route.query.projectId);
    }
    if (
      this.$route.query.code != "" &&
      this.$route.query.code != undefined &&
      this.$route.query.code != null
    ) {
      this.getWXCode(this.$route.query.code);
    }
  },
  methods: {
    //登录
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.submitApi();
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    //登录接口
    async submitApi() {
      const { data: data } = await this.$http.post("auth/login", this.ruleForm);
      localStorage.setItem("token", data.data.access_token);
      this.$toast(data.message);
      if (this.$route.query.projectId) {
        this.$router.replace({
          path: "/foreignSpeakers",
          query: {
            projectId: window.localStorage.getItem("projectId"),
          },
        });
      } else {
        this.$router.replace("/home");
      }
      // this.$router.push("/home");
    },
    //微信登录
    wxgetMobileCode() {
      this.ruleForm.phone = "";
      let appid = "wx34a084fac740b3d0";
      let redirect_uri = encodeURIComponent(
        "http://speaker.mobile.netloop.cn/login"
      );
      window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appid}&redirect_uri=${redirect_uri}&response_type=code&scope=snsapi_userinfo&state=STATUS#wechat_redirect`;
    },
    //获取conde
    async getWXCode(code) {
      try {
        const { data: data } = await this.$http.post("wechat/login", {
          code,
          state: "GETCODE",
        });
        if (
          data.data.access_token != "" &&
          data.data.access_token != undefined &&
          data.data.access_token != null
        ) {
          localStorage.setItem("token", data.data.access_token);
          this.$toast(data.message);
          if (
            localStorage.getItem("projectId") != "" &&
            localStorage.getItem("projectId") != undefined &&
            localStorage.getItem("projectId") != null
          ) {
            this.$router.replace({
              path: "/foreignSpeakers",
              query: {
                projectId: localStorage.getItem("projectId"),
              },
            });
          } else {
            this.$router.replace("/home");
          }
        } else {
          this.open_id = data.data.open_id;
          this.bindShow = true;
        }
      } catch (error) {
        localStorage.removeItem("code");
      }
    },
    async onBeforeClose(action, done) {
      if (action === "confirm") {
        try {
          const { data: data } = await this.$http.post("wechat/bind", {
            open_id: this.open_id,
            phone: this.ruleForm.phone, //手机号
            code: this.inputco, //验证码
            company_name: this.inputgo, //公司名称
          });

          localStorage.setItem("token", data.data.access_token);
          this.$toast(data.message);
          // this.$router.replace("/home");
          if (
            localStorage.getItem("projectId") != "" &&
            localStorage.getItem("projectId") != undefined &&
            localStorage.getItem("projectId") != null
          ) {
            this.$router.replace({
              path: "/foreignSpeakers",
              query: {
                projectId: localStorage.getItem("projectId"),
              },
            });
          } else {
            this.$router.replace("/home");
          }
          return done(true); //关闭弹窗
        } catch (error) {
          return done(false); // 阻止弹窗关闭
        }
      } else {
        // 点击取消走这里
        done(true); // 关闭弹窗, true可以省略
      }
    },
    //绑定手机号
    async bindShowSubmission() {
      return;
      const { data: data } = await this.$http.post("wechat/bind", {
        open_id: this.open_id,
        phone: this.ruleForm.phone, //手机号
        code: this.inputco, //验证码
        company_name: this.inputgo, //公司名称
      });

      localStorage.setItem("token", data.data.access_token);
      this.$toast(data.message);
      // this.$router.replace("/home");
      if (
        localStorage.getItem("projectId") != "" &&
        localStorage.getItem("projectId") != undefined &&
        localStorage.getItem("projectId") != null
      ) {
        this.$router.replace({
          path: "/foreignSpeakers",
          query: {
            projectId: localStorage.getItem("projectId"),
          },
        });
      } else {
        this.$router.replace("/home");
      }
    },
  },
};
</script>
<style lang="less" scoped>
@import "@/assets/css/980.css";
@import "@/assets/css/768.css";
@import "@/assets/css/550.css";
@import "@/assets/css/410.css";
@import "@/assets/css/320.css";
.login {
  position: relative;
  .login-bgc {
    width: 158px;
    height: 95px;
    // margin: 137px auto 100px;
    margin: 60px auto 100px;
  }
  .login-box {
    position: relative;
    padding: 0 25px;
  }
  .forget {
    text-align: right;
    position: relative;
    top: -14px;
    right: -8px;
    color: #00acbe;
    font-size: 13px;
  }
  .login-btn {
    width: 100%;
    margin-top: 80px;
  }
  .register {
    font-size: 13px;
    text-align: right;
    margin-top: 10px;
    .register-text {
      color: #00acbe;
    }
  }

  .weixin {
    margin-top: 48px;
    display: flex;
    flex-direction: column;
    align-items: center;
    .weixin-box {
      display: flex;
      align-items: center;
      margin-bottom: 30px;
      .weixin-box-gang {
        width: 100px;
        height: 1px;
        background-color: #ececec;
      }
      .weixin-box-name {
        color: #647186;
        font-size: 13px;
        margin: 0 8px;
      }
    }
    .weixin-img {
      width: 27px;
      height: 22px;
    }
  }
  .pw-text {
    font-size: 12px;
    color: #ccc;
    margin: 13px 15px;
  }
}
</style>
