<template>
  <div class="tabBar">
    <van-tabbar
      v-model="active"
      active-color="#0E9BA9"
      @change="onChange"
      v-if="$route.meta.isShowtabbar"
    >
      <van-tabbar-item name="home" icon="wap-home">首页</van-tabbar-item>
      <van-tabbar-item name="my" icon="friends">我的</van-tabbar-item>
    </van-tabbar>
  </div>
</template>
<script>
export default {
  name: "tabBar",
  data() {
    return {
      active: "home",
    };
  },
  watch: {
    $route: {
      handler: function (val, oldVal) {
        this.active = val.name;
      },
      deep: true, // 深度监听
      immediate: true, // 第一次初始化渲染就可以监听到
    },
  },
  methods: {
    onChange(index) {
      this.$router.push({ name: index });
    },
  },
};
</script>
<style lang="less"></style>
