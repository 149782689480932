<template>
  <div>
    <el-button
      :disabled="disable"
      :class="{ codeGeting: isGeting }"
      @click="getVerifyCode"
      style="background: #fff; color: #00acbe; border: none; padding: 0"
      v-prevent="2000"
      >{{ getCode }}
    </el-button>
  </div>
</template>

<script>
export default {
  name: "getCode",
  props: ["ruleForm", "type"],
  data() {
    return {
      countDownNum: 60,
      getCode: "获取验证码",
      isGeting: false,
      disable: false,
    };
  },
  methods: {
    //发送验证码
    getVerifyCode() {
      this.disable = true;
      this.getSms();
    },
    async getSms() {
      const { data: data } = await this.$http.post("auth/sms", {
        phone: this.ruleForm.phone,
        type: this.type,
      });
      if (data.code == 200) {
        this.disable = true;
        this.getCoun();
        // this.$message({
        //   message: data.message,
        //   type: "success",
        // });
        this.$toast(data.message);
      } else {
        this.disable = false;
        this.$toast(data.message);
        // this.$message.error({
        //   message: data.message,
        // });
      }
    },
    //定时器
    getCoun() {
      let time = setInterval(() => {
        this.countDownNum--;
        this.getCode = `${this.countDownNum}s 后重新发送`;
        if (this.countDownNum < 0) {
          this.getCode = "获取验证码";
          this.countDownNum = 60;
          this.disable = false;
          clearInterval(time);
        }
      }, 1000);
    },
  },
};
</script>
<style lang="less" scoped></style>
