import Vue from 'vue'
import VueRouter from 'vue-router'
import login from '@/views/entrance/login.vue' 
import forgetPwd from '@/views/entrance/forgetPwd.vue' 
import registered from '@/views/entrance/registered.vue' 
import foreignSpeakers from '@/views/foreignSpeakers/index.vue' 

import home from '@/views/home/index.vue' 
import my from '@/views/my/index.vue' 
import collection from '@/views/collection/index.vue' 
import myfollow from '@/views/myfollow/index.vue' 
import compare from '@/views/compare/index.vue' 

Vue.use(VueRouter)  
 
const routes = [{
        path: '/',
        redirect: '/home' 
},
  { 
        path: '/login',
        name: 'login',
        component: login 
  },
            {
        path: '/forgetPwd',
        name: 'forgetPwd',
        component: forgetPwd
  },
        {
        path: '/registered',
        name: 'registered',
        component: registered
    }, 
    //外籍讲者   
      { 
        path: '/home',
        name: 'home',
        component: home,
        meta: {
          isShowtabbar: true,
        },
  },
      {  
        path: '/my', 
        name: 'my',
        component: my,
        meta: {
          isShowtabbar: true,  
        },
  }, 
     { 
        path: '/myfollow',
        name: 'myfollow',
        component: myfollow 
  },  
   { 
        path: '/collection',
        name: 'collection',
        component: collection
  },  
     { 
        path: '/compare',
        name: 'compare',
        component: compare
  },   
    { 
        path: '/foreignSpeakers',
        name: 'foreignSpeakers',
        component: foreignSpeakers,
        // meta: {
        //     keepAlive: true // 需要被缓存  
        // }
    },
]
 
const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

//* 判断用户是否登录  && to.path !== '/fillInQuestionnaires'
// router.beforeEach((to, from, next) => { 
// if (to.path !== '/login' && to.path !== '/forgetPwd' && to.path !== '/registered') {
//     if (!window.localStorage.getItem('token')) {  
//         router.push('/login');  
//     } else {
//         next()
//     } 
// } else { 
//     next() 
// }  
// }) 

export default router