<template>
  <div id="app">
    <tab-bar />
    <keep-alive>
      <router-view v-if="$route.meta.keepAlive" />
    </keep-alive>
    <router-view v-if="!$route.meta.keepAlive" />
  </div>
</template>
<script>
import tabBar from "./components/tabBar/index";
export default {
  components: {
    tabBar,
  },
  data() {
    return {};
  },
};
</script>
<style lang="less">
@import "@/assets/css/980.css";
@import "@/assets/css/768.css";
@import "@/assets/css/550.css";
@import "@/assets/css/410.css";
@import "@/assets/css/320.css";
.van-nav-bar .van-icon {
  color: #000000 !important;
}
</style>
