import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './assets/css/base.css'
import http from './utils/cesguToken'
Vue.prototype.$http = http
Vue.config.productionTip = false
  //筛选弹窗 全局事件总线
Vue.prototype.$popuph = false

//节流指令
import prevent from './utils/throttle'
Vue.use(prevent);
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';


import Vant from 'vant';
import 'vant/lib/index.css';
Vue.use(Vant);


//新手引导 引用driver npm install driver.js@0.9.8 --save 
import Driver from 'driver.js'
import 'driver.js/dist/driver.min.css'
Vue.prototype.$driver = new Driver({
    className: "projectManagement", //新手指引的容器名字，标号1的地方   
    padding: 0,
    animate: true, // 更改突出显示元素时设置动画 
    allowClose: false, //点击覆盖是否应该关闭
    overlayClickNext: false, //如果它移动到覆盖单击的下一步
    showButtons: true, // 是否在页脚中显示控制按钮
    keyboardControl: false, // 允许通过键盘进行控制（退出以关闭，箭头键移动） 
    doneBtnText: '我知道了', 
    closeBtnText: '跳过', 
    nextBtnText: '下一步',
    prevBtnText: '上一步',
}) 

Vue.use(ElementUI)
new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')