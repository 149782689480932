<template>
  <div class="login">
    <div class="login-bgc">
      <img src="@/assets/image/entrance/login-bgc.png" />
    </div>
    <div class="login-box">
      <el-form :model="ruleForm" :rules="rules" ref="ruleForm">
        <el-form-item>
          <el-input
            placeholder="请输入公司名称"
            v-model="ruleForm.company"
          ></el-input>
        </el-form-item>

        <el-form-item prop="realname">
          <el-input
            placeholder="请输入姓名（必填）"
            v-model="ruleForm.realname"
          ></el-input>
        </el-form-item>

        <el-form-item>
          <el-input
            placeholder="请输入邮箱"
            v-model="ruleForm.email"
          ></el-input>
        </el-form-item>

        <el-form-item prop="phone">
          <el-input
            placeholder="请输入手机号（必填）"
            v-model="ruleForm.phone"
          ></el-input>
        </el-form-item>

        <div style="position: relative">
          <el-form-item prop="code" class="add-password">
            <el-input
              type="text"
              v-model="ruleForm.code"
              autocomplete="off"
              clearable
              placeholder="请输入验证码"
            ></el-input>
          </el-form-item>
          <div class="code-position">
            <get-code :ruleForm="ruleForm" :type="'REG'" />
          </div>
        </div>

        <el-form-item prop="password">
          <el-input
            placeholder="请输入密码（必填）"
            v-model="ruleForm.password"
            show-password
          ></el-input>
        </el-form-item>

        <div class="login-btn">
          <el-button
            style="
              background: #0e9ba9;
              color: #fff;
              width: 100%;
              border: #0e9ba9;
            "
            type="primary"
            @click="submitForm('ruleForm')"
            v-prevent="2000"
            >注册</el-button
          >
        </div>
        <div class="register">
          <span>已有账号？</span
          ><span class="register-text" @click="$router.push('/login')"
            >直接登录</span
          >
        </div>
      </el-form>
    </div>
  </div>
</template>
<script>
import getCode from "@/components/getCode/index.vue";
export default {
  name: "registered",
  components: {
    getCode,
  },
  data() {
    //姓名
    var validatePass3 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入姓名"));
      } else {
        callback();
      }
    };
    //手机
    var validatePass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入手机号"));
      } else {
        callback();
      }
    };
    //密码
    var validatePass2 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入密码"));
      } else {
        callback();
      }
    };
    //验证码
    var validatePass4 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入验证码"));
      } else {
        callback();
      }
    };
    return {
      ruleForm: {
        phone: "", //手机号
        password: "", //密码
        company: "", //公司名称
        code: "", //验证码
        email: "", //邮箱
        realname: "",
      },
      rules: {
        phone: [{ validator: validatePass, trigger: "blur" }],
        password: [{ validator: validatePass2, trigger: "blur" }],
        realname: [{ validator: validatePass3, trigger: "blur" }],
        code: [{ validator: validatePass4, trigger: "blur" }],
      },
    };
  },
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.loginApi();
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    //登录接口
    async loginApi() {
      const { data: data } = await this.$http.post(
        "auth/register",
        this.ruleForm
      );
      // this.$message.success(data.message);
      this.$toast(data.message);
      this.$router.push("/login");
    },
  },
};
</script>
<style lang="less" scoped>
@import "@/assets/css/980.css";
@import "@/assets/css/768.css";
@import "@/assets/css/550.css";
@import "@/assets/css/410.css";
@import "@/assets/css/320.css";
.login {
  position: relative;
  .login-bgc {
    width: 158px;
    height: 95px;
    margin: 60px auto 60px;
  }
  .login-box {
    position: relative;
    padding: 0 25px;
  }
  .forget {
    text-align: right;
    position: relative;
    top: -14px;
    right: -8px;
    color: #00acbe;
    font-size: 13px;
  }
  .login-btn {
    width: 100%;
    margin-top: 40px;
  }

  .code-position {
    z-index: 999;
    position: absolute;
    top: 11px;
    right: 10px;
  }
  .register {
    font-size: 13px;
    text-align: right;
    margin-top: 10px;
    .register-text {
      color: #00acbe;
    }
  }
}
</style>
