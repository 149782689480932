<template>
  <div class="compare">
    <van-nav-bar title="对比" fixed left-arrow @click-left="$router.back()" />
    <div class="compare-waijiang">
      <img src="@/assets/image/home/waijiang.png" />
    </div>
    <div class="compare-box">
      <ul class="foreign-speakers-contents-ul" v-if="queryList.length">
        <li
          class="foreign-speakers-contents-li"
          v-for="(item, index) in queryList"
          :key="index"
        >
          <!-- 关注 取消关注 -->
          <div
            class="guanzhu-quxiao"
            @click.stop.prevent="expertFollowClick(item)"
          >
            <div
              class="foreinger-list-item-contents-right-box"
              v-if="item.is_follow == 0"
            >
              <div class="foreinger-list-item-contents-right-box-img">
                <img src="@/assets/image/home/guanzhu.png" />
              </div>
              <div class="foreinger-list-item-contents-right-box-text">
                关注
              </div>
            </div>
            <div
              class="foreinger-list-item-contents-right-box"
              v-if="item.is_follow == 1"
            >
              <div class="foreinger-list-item-contents-right-box-img">
                <img src="@/assets/image/home/quxiaoguanzhu.png" />
              </div>
              <div class="foreinger-list-item-contents-right-box-text">
                取消关注
              </div>
            </div>
          </div>
          <!-- 关注 取消关注 -->
          <!-- 标题 -->
          <div class="biaoti">
            <!-- 头像 -->
            <div class="foreign-speakers-contents-li-img-box">
              <div class="foreign-speakers-contents-li-img">
                <img :src="item.photo" />
              </div>
            </div>
            <div class="foreign-speakers-contents-li-name">
              {{ item.name }}
            </div>
          </div>
          <!-- 标签 -->
          <div class="foreign-speakers-contents-li-biaoqian" v-if="item.tags">
            <span class="span-biaoqian" style="background-color: #bee3df">
              {{ item.tags.split(",")[0] }}
            </span>
            <span style="background-color: #f3d0d0" class="span-biaoqian">
              {{ item.tags.split(",")[1] }}
            </span>
            <span class="span-biaoqian" style="background-color: #d0e5f9">
              {{ item.tags.split(",")[2] }}
            </span>
          </div>
          <!-- 国家 -->
          <div class="foreign-speakers-contents-li-country">
            <span class="country-one"></span>
            <span class="country-two">国家：</span>
            <span class="country-three">{{ item.country }}</span>
          </div>
          <!-- 任职 -->
          <div class="foreign-speakers-contents-li-appointments">
            <div class="appointments-dis">
              <span class="appointments-one"></span>
              <span class="appointments-two">就职机构及任职：</span>
            </div>
            <div class="appointments-three">{{ item.institution }}</div>
          </div>
          <!-- 折叠 -->
          <el-collapse accordion>
            <el-collapse-item>
              <!-- 折叠内容 -->
              <div class="foreignSpeakersDetails-contents-bottom">
                <!-- 就职机构 -->
                <div
                  class="foreignSpeakersDetails-contents-bottom-position"
                  v-if="item.institution"
                >
                  <div
                    class="foreignSpeakersDetails-contents-bottom-position-name"
                  >
                    就职机构及职位
                  </div>
                  <div class="none-zhe"></div>
                  <div
                    class="foreignSpeakersDetails-contents-bottom-position-text"
                  >
                    {{ item.institution }}
                  </div>
                </div>
                <!-- 擅长领域 -->
                <div
                  class="foreignSpeakersDetails-contents-bottom-position"
                  v-if="item.areas_of_expertise"
                >
                  <div
                    class="foreignSpeakersDetails-contents-bottom-position-name top"
                  >
                    擅长领域
                  </div>
                  <div
                    class="foreignSpeakersDetails-contents-bottom-position-text"
                  >
                    {{ item.areas_of_expertise }}
                  </div>
                </div>
                <!-- 学协会及任职 -->
                <div
                  class="foreignSpeakersDetails-contents-bottom-position"
                  v-if="item.association"
                >
                  <div
                    class="foreignSpeakersDetails-contents-bottom-position-name top"
                  >
                    学协会及任职
                  </div>
                  <div
                    class="foreignSpeakersDetails-contents-bottom-position-text"
                  >
                    {{ item.association }}
                  </div>
                </div>
                <!-- 演讲研究赞助商 -->
                <div
                  class="foreignSpeakersDetails-contents-bottom-position"
                  v-if="item.research_sponsor"
                >
                  <div
                    class="foreignSpeakersDetails-contents-bottom-position-name top"
                  >
                    演讲研究赞助商
                  </div>
                  <div
                    class="foreignSpeakersDetails-contents-bottom-position-text"
                  >
                    {{ item.research_sponsor }}
                  </div>
                </div>
                <!-- 会议演讲及题目 -->
                <div
                  class="foreignSpeakersDetails-contents-bottom-position"
                  v-if="item.presenting_meeting"
                >
                  <div
                    class="foreignSpeakersDetails-contents-bottom-position-name top"
                  >
                    会议演讲及题目
                  </div>
                  <div
                    class="foreignSpeakersDetails-contents-bottom-position-text"
                  >
                    {{ item.presenting_meeting }}
                  </div>
                </div>
                <!-- 期刊及任职及影响因子 -->
                <div
                  class="foreignSpeakersDetails-contents-bottom-position"
                  v-if="item.journal_position"
                >
                  <div
                    class="foreignSpeakersDetails-contents-bottom-position-name top"
                  >
                    期刊及任职及影响因子
                  </div>
                  <div
                    class="foreignSpeakersDetails-contents-bottom-position-text"
                  >
                    {{ item.journal_position }}
                  </div>
                </div>
              </div>
              <!-- 折叠内容 -->
            </el-collapse-item>
          </el-collapse>
          <!-- 折叠 -->
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
export default {
  name: "compare",
  data() {
    return {
      queryList: [],
    };
  },
  created() {
    this.queryList = JSON.parse(this.$route.query.list);
  },
  mounted() {
    this.getDetailList();
  },
  methods: {
    //列表
    async getDetailList() {
      const { data: data } = await this.$http.post("speaker/detail", {
        id: this.queryList,
      });
      this.queryList = data.data;
    },
    //关注 取消关注 点击
    expertFollowClick(item) {
      let status = 0;
      if (item.is_follow == 0) {
        status = 1;
      }
      if (item.is_follow == 1) {
        status = 0;
      }
      if (status == 0) {
        this.$dialog
          .confirm({
            title: "提示",
            message: "您是否要取消关注？",
          })
          .then(() => {
            this.getuserFollow(item.id, status);
          })
          .catch(() => {});
      } else {
        this.getuserFollow(item.id, status);
      }
    },
    //关注 取消关注 接口
    async getuserFollow(info_id, status) {
      const { data: data } = await this.$http.post("speaker/follow", {
        info_id,
        type_id: 8,
        status,
      });
      this.getForeignSpeakerList();
      this.$toast(data.message);
    },
  },
};
</script>
<style lang="less" scoped>
.compare {
  .compare-waijiang {
    margin-top: 46px;
    width: 102px;
    height: 33px;
    margin: 66px 15px 0px 15px;
  }
  .compare-box {
  }
  .foreign-speakers-contents-ul {
    position: relative;
    // padding: 70px 15px 0 15px;
    padding: 15px 15px 0 15px;
    margin-bottom: 20px;

    //奇数
    .foreign-speakers-contents-li:nth-child(odd) {
      //标题
      .foreign-speakers-contents-li-name {
        flex: 1;
        padding: 9px 10px;
        display: flex;
        align-items: center;
        border-radius: 17px;
        font-size: 16px;
        background-color: #d5e3fe;
        color: #437beb;
      }
    }
    //偶数
    .foreign-speakers-contents-li:nth-child(even) {
      //标题
      .foreign-speakers-contents-li-name {
        flex: 1;
        padding: 9px 10px;
        display: flex;
        align-items: center;
        border-radius: 17px;
        font-size: 16px;
        background-color: #f3daf7;
        color: #a739bb;
      }
    }
    //每一个模块
    .foreign-speakers-contents-li {
      position: relative;
      // padding: 12px 10px;
      padding: 14px 10px;
      margin-bottom: 15px;
      border-radius: 8px;
      background-color: #ffffff;
      box-shadow: 0px 3px 8px #e0dfff;
      &:last-child {
        margin-bottom: 0;
      }
      .foreign-speakers-contents-li-guanzhu {
        position: absolute;
        top: -25px;
        display: flex;
        justify-content: flex-end;
      }
      .guanzhu-quxiao {
        position: relative;
        top: -24px;
        right: 0;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        font-size: 13px;
        color: #1e1e1e;
        // margin-bottom: 10px;
        .foreinger-list-item-contents-right-box {
          display: flex;
          align-items: center;
          .foreinger-list-item-contents-right-box-img {
            margin-top: 4px;
            margin-right: 3px;
            width: 16px;
            height: 16px;
          }
        }
      }
      //国家
      .foreign-speakers-contents-li-country {
        display: flex;
        align-items: center;
        margin-top: 14px;
        font-size: 15px;
        color: #1e1e1e;
        .country-one {
          display: block;
          width: 10px;
          height: 4px;
          background-color: #4584f2;
          margin-right: 6px;
        }
        .country-two {
          width: 46px;
          font-weight: 600;
        }
        .country-three {
          flex: 1;
        }
      }
      //任职
      .foreign-speakers-contents-li-appointments {
        margin: 18px 0 15px 0;
        font-size: 15px;
        color: #1e1e1e;
        .appointments-dis {
          display: flex;
          align-items: center;
          .appointments-one {
            display: block;
            width: 10px;
            height: 4px;
            background-color: #23a519;
            margin-right: 6px;
          }
          .appointments-two {
            font-weight: 600;
          }
        }
        .appointments-three {
          margin-top: 10px;
          line-height: 25px;
        }
      }
    }

    .biaoti {
      display: flex;
      //头像
      .foreign-speakers-contents-li-img-box {
        width: 36px;
        margin-right: 8px;
        .foreign-speakers-contents-li-img {
          width: 36px;
          height: 36px;
          overflow: hidden;
          border-radius: 50%;
          img {
            width: 100%;
            height: 100%;
          }
        }
      }
    }

    .foreign-speakers-contents-li-biaoqian {
      display: flex;
      align-items: center;
      margin-top: 14px;
      font-size: 13px;
      color: #212529;
      font-weight: 600;
      .span-biaoqian {
        padding: 6px 12px;
        border-radius: 3px;
        margin-right: 12px;
      }
    }

    /deep/.el-collapse {
      border: none !important;
    }
    /deep/ .el-collapse-item__header {
      border-bottom: none !important;
      height: 30px !important;
      line-height: 30px !important;
    }
  }
}
</style>
