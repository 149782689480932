<template>
  <div class="foreign-speakers">
    <van-nav-bar
      :title="shareName"
      fixed
      left-arrow
      @click-left="$router.push('/collection')"
    />
    <div class="collection-bgc"><img src="@/assets/image/my/bgc.png" /></div>

    <!-- 内容 -->
    <div class="foreign-speakers-contents">
      <van-list
        v-model="loading"
        :finished="finished"
        finished-text="没有更多了"
        @load="onLoad"
        :immediate-check="false"
      >
        <div class="foreign-speakers-img-log">
          <img src="@/assets/image/my/wai.png" />
        </div>
        <!-- 搜索 -->
        <div class="foreign-speakers-seach">
          <van-button @click="saveClick" type="info" size="small"
            >保存合集</van-button
          >
          <van-button
            style="margin-left: 5px"
            @click="refreshClick"
            type="primary"
            size="small"
            >重置</van-button
          >
          <van-search
            class="foreign-speakers-seach-input"
            shape="round"
            v-model="value"
            placeholder="请输入关键词搜索"
            @search="onSearch"
          />
        </div>

        <!-- 暂无数据 -->
        <van-empty
          style="margin: 20% 0"
          description="暂无数据"
          v-if="collection.list.length == 0"
        />
        <ul class="foreign-speakers-contents-ul" v-if="collection.list.length">
          <li
            class="foreign-speakers-contents-li"
            v-for="(item, index) in collection.list"
            :key="index"
          >
            <div class="foreign-speakers-contents-li-left-top">
              <div
                class="foreign-speakers-contents-li-left-top-left"
                @click="recommend(item)"
              >
                <div class="foreign-speakers-contents-li-left-top-left-img">
                  <img src="@/assets/image/foreignSpeakers/tui.png" />
                </div>
                <div>推荐</div>
              </div>
              <div
                class="foreign-speakers-contents-li-left-top-right"
                @click="deleteItem(item)"
              >
                <div class="foreign-speakers-contents-li-left-top-left-img">
                  <img src="@/assets/image/foreignSpeakers/shan.png" />
                </div>
                <div>删除</div>
              </div>
            </div>
            <!-- @click="foreignSpeakersDetails(item)" -->
            <div class="recommend-box">
              <div class="recommend-box-on">
                <div
                  class="recommend-box-on-text-none"
                  v-if="item.pivot.order == 0"
                ></div>
                <div class="recommend-box-on-text" v-else>
                  <div v-if="item.pivot.order == 1">首推</div>
                  <div v-if="item.pivot.order == 2">第二推荐</div>
                  <div v-if="item.pivot.order == 3">第三推荐</div>
                  <div v-if="item.pivot.order == 4">第四推荐</div>
                  <div v-if="item.pivot.order == 5">第五推荐</div>
                  <div v-if="item.pivot.order == 6">第六推荐</div>
                </div>
                <div
                  class="recommend-box-on-box"
                  @click="recommendClick(item, index)"
                >
                  <div class="recommend-box-on-box-zan">
                    <div class="recommend-box-on-box-zan-img">
                      <!--  -->
                      <img
                        v-if="aaaaaa[index].show"
                        src="../../assets/image/foreignSpeakers/zan.png"
                      />
                      <img
                        v-else
                        src="../../assets/image/foreignSpeakers/zan-xuan.png"
                      />
                    </div>
                  </div>
                  <div class="recommend-box-on-box-num">
                    <!-- {{item.pivot.like}} -->
                    {{ item.pivot.like + aaaaaa[index].num }}
                  </div>
                </div>
              </div>
              <div class="recommend-box-liyou" v-if="item.pivot.argument">
                <div class="recommend-box-liyou-name">推荐理由：</div>
                <div class="recommend-box-liyou-text">
                  {{ item.pivot.argument }}
                </div>
              </div>
            </div>
            <!-- 标题 -->
            <div class="biaoti">
              <!-- 头像 -->
              <div class="foreign-speakers-contents-li-img-box">
                <div class="foreign-speakers-contents-li-img">
                  <img :src="item.photo" />
                </div>
              </div>
              <div class="foreign-speakers-contents-li-name">
                {{ item.name }}
              </div>
            </div>
            <!-- 国家 -->
            <div class="foreign-speakers-contents-li-country">
              <span class="country-one"></span>
              <span class="country-two">国家：</span>
              <span class="country-three">{{ item.country }}</span>
            </div>
            <!-- 任职 -->
            <div class="foreign-speakers-contents-li-appointments">
              <div class="appointments-dis">
                <span class="appointments-one"></span>
                <span class="appointments-two">就职机构及任职：</span>
              </div>
              <div class="appointments-three">{{ item.institution }}</div>
            </div>
            <!-- 折叠 -->
            <el-collapse accordion @change="handleChange">
              <el-collapse-item :title="isFold">
                <!-- 折叠内容 -->
                <div class="foreignSpeakersDetails-contents-bottom">
                  <!-- 就职机构 -->
                  <div
                    class="foreignSpeakersDetails-contents-bottom-position"
                    v-if="item.institution"
                  >
                    <div
                      class="foreignSpeakersDetails-contents-bottom-position-name"
                    >
                      就职机构及职位
                    </div>
                    <div class="none-zhe"></div>
                    <div
                      class="foreignSpeakersDetails-contents-bottom-position-text"
                    >
                      {{ item.institution }}
                    </div>
                  </div>
                  <!-- 擅长领域 -->
                  <div
                    class="foreignSpeakersDetails-contents-bottom-position"
                    v-if="item.areas_of_expertise"
                  >
                    <div
                      class="foreignSpeakersDetails-contents-bottom-position-name top"
                    >
                      擅长领域
                    </div>
                    <div
                      class="foreignSpeakersDetails-contents-bottom-position-text"
                    >
                      {{ item.areas_of_expertise }}
                    </div>
                  </div>
                  <!-- 学协会及任职 -->
                  <div
                    class="foreignSpeakersDetails-contents-bottom-position"
                    v-if="item.association"
                  >
                    <div
                      class="foreignSpeakersDetails-contents-bottom-position-name top"
                    >
                      学协会及任职
                    </div>
                    <div
                      class="foreignSpeakersDetails-contents-bottom-position-text"
                    >
                      {{ item.association }}
                    </div>
                  </div>
                  <!-- 演讲研究赞助商 -->
                  <div
                    class="foreignSpeakersDetails-contents-bottom-position"
                    v-if="item.research_sponsor"
                  >
                    <div
                      class="foreignSpeakersDetails-contents-bottom-position-name top"
                    >
                      演讲研究赞助商
                    </div>
                    <div
                      class="foreignSpeakersDetails-contents-bottom-position-text"
                    >
                      {{ item.research_sponsor }}
                    </div>
                  </div>
                  <!-- 会议演讲及题目 -->
                  <div
                    class="foreignSpeakersDetails-contents-bottom-position"
                    v-if="item.presenting_meeting"
                  >
                    <div
                      class="foreignSpeakersDetails-contents-bottom-position-name top"
                    >
                      会议演讲及题目
                    </div>
                    <div
                      class="foreignSpeakersDetails-contents-bottom-position-text"
                    >
                      {{ item.presenting_meeting }}
                    </div>
                  </div>
                  <!-- 期刊及任职及影响因子 -->
                  <div
                    class="foreignSpeakersDetails-contents-bottom-position"
                    v-if="item.journal_position"
                  >
                    <div
                      class="foreignSpeakersDetails-contents-bottom-position-name top"
                    >
                      期刊及任职及影响因子
                    </div>
                    <div
                      class="foreignSpeakersDetails-contents-bottom-position-text"
                    >
                      {{ item.journal_position }}
                    </div>
                  </div>
                </div>
                <!-- 折叠内容 -->
              </el-collapse-item>
            </el-collapse>
            <!-- 折叠 -->
          </li>
        </ul>
      </van-list>
    </div>

    <van-dialog
      v-model="recommendShow"
      title="推荐"
      show-cancel-button
      @confirm="referralSubmission"
    >
      <van-form validate-first>
        <van-field
          readonly
          clickable
          name="picker"
          :value="recommend_id"
          label="推荐："
          placeholder="请选择"
          @click="showPicker = true"
        />
        <van-popup v-model="showPicker" position="bottom">
          <van-picker
            show-toolbar
            :columns="columns"
            @confirm="onConfirm"
            @cancel="showPicker = false"
          />
        </van-popup>
        <van-field
          v-model="argument"
          name="推荐理由："
          label="推荐理由："
          placeholder="请输入"
          type="textarea"
        />
      </van-form>
    </van-dialog>
  </div>
</template>

<script>
import wx from "weixin-js-sdk";
export default {
  name: "foreignSpeakers",
  data() {
    return {
      recommendText: "",
      showPicker: false,
      username: "",
      recommend_id: "",
      argument: "",
      columns: [
        "首推",
        "第二推荐",
        "第三推荐",
        "第四推荐",
        "第五推荐",
        "第六推荐",
      ],
      recommendShow: false,
      selectLike: false,
      value: "",
      loading: false, //加载状态不需要再触发onLoad
      finished: false, //完成加载不需要触发onLoad
      page: 1, //第几页
      limit: 8, //一页几个
      shareName: "",
      collection: { list: [] }, //合集数据
      ShareDomainName: "https://speaker.mobile.netloop.cn/foreignSpeakers",
      aaaaaa: [],
      lastClickTime: 0,
      isFold: "展开",
    };
  },
  created() {
    if (this.$route.query.projectId) {
      localStorage.removeItem("projectId");
      localStorage.setItem("projectId", this.$route.query.projectId);
      this.getForeignSpeakerList(this.$route.query.projectId); //只需要触发一次
    }
  },
  mounted() {},
  methods: {
    //保存合集
    async saveClick() {
      if (!window.localStorage.getItem("token")) {
        this.$dialog
          .alert({
            title: "温馨提示",
            message:
              "您还未登录账号，登录后可将合集保存至自己账号下,是否要登录？",
          })
          .then(() => {
            this.$router.push({
              path: "/login",
              query: {
                projectId: this.$route.query.projectId,
              },
            });
          });
      } else {
        this.replicateCollectionApi();
      }
    },
    //保存合集接口
    async replicateCollectionApi() {
      const { data: data } = await this.$http.post(
        "speaker/replicate/collection",
        { collection_id: localStorage.getItem("projectId") }
      );
      this.$toast(data.message);
    },
    //回车
    onSearch(e) {
      this.page = 1;
      this.getForeignSpeakerList(this.$route.query.projectId);
    },
    //重置
    refreshClick() {
      this.value = "";
      this.page = 1;
      this.getForeignSpeakerList(this.$route.query.projectId);
    },
    //跳转详情
    foreignSpeakersDetails(item) {
      this.$router.push({
        path: "/foreignSpeakersDetails",
        query: {
          id: item.id,
          name: this.shareName,
        },
      });
    },
    //列表
    async getForeignSpeakerList(collection_id) {
      const { data: data } = await this.$http.post(
        "speaker/share/collectDetail",
        {
          collection_id,
          name: this.value,
          page: this.page,
          limit: this.limit,
        }
      );
      document.title = data.data.collection_name;
      this.collection = data.data;
      this.shareName = data.data.collection_name;
      this.WXShare();
      this.collection.list.forEach((item) => {
        return this.aaaaaa.push({ show: true, num: 0 });
      });
    },
    //展开折叠事件
    handleChange(val) {
      if (val != "") {
        this.isFold = "收起";
      } else {
        this.isFold = "展开";
      }
    },
    //上拉加载列表
    async getLoadingForeignSpeakerList() {
      const { data: data } = await this.$http.post(
        "speaker/share/collectDetail",
        {
          collection_id: this.$route.query.projectId,
          name: this.value,
          page: this.page,
          limit: this.limit,
        }
      );
      this.loading = false; //加载状态不需要再触发onLoad
      this.collection.list.push(...data.data.list);
      if (this.collection.list.length >= data.data.total) this.finished = true; //完成加载不需要触发onLoad
      //3上拉加载
      this.collection.list.forEach((item) => {
        return this.aaaaaa.push({ show: true, num: 0 });
      });
    },
    //上拉加载
    async onLoad() {
      this.page++;
      this.getLoadingForeignSpeakerList();
    },
    //点赞推荐
    async recommendClick(item, index) {
      if (!this.aaaaaa[index].show) {
        //已经点赞
      } else {
        //暂未点赞
        const { data: data } = await this.$http.post(
          "speaker/giveLike/speaker",
          {
            collection_id: this.$route.query.projectId,
            speaker_id: item.id,
          }
        );
        this.$toast(data.message);
        this.aaaaaa[index].show = false;
        this.aaaaaa[index].num += 1;
      }
    },
    //推荐
    recommend(item) {
      this.recommendText = item.id;
      this.recommendShow = true;
      this.recommend_id = "";
      this.argument = "";
    },
    //推荐提交
    async referralSubmission() {
      let aaa = "";
      if (this.recommend_id == "首推") aaa = "1";
      if (this.recommend_id == "第二推荐") aaa = "2";
      if (this.recommend_id == "第三推荐") aaa = "3";
      if (this.recommend_id == "第四推荐") aaa = "4";
      if (this.recommend_id == "第五推荐") aaa = "5";
      if (this.recommend_id == "第六推荐") aaa = "6";
      const { data: data } = await this.$http.post(
        "speaker/recommend/speaker",
        {
          collection_id: this.$route.query.projectId,
          speaker_id: this.recommendText,
          recommend_id: aaa,
          argument: this.argument,
        }
      );
      this.$toast(data.message);
      // this.getForeignSpeakerList(this.$route.query.projectId); //只需要触发一次
      location.reload();

      this.recommendShow = false;
    },
    //删除
    deleteItem(item) {
      this.$dialog
        .confirm({
          title: "提示",
          message: "您是否要删除",
        })
        .then(async () => {
          const { data: data } = await this.$http.post(
            "speaker/detail/delete",
            {
              collection_id: this.$route.query.projectId,
              speaker_id: item.id,
            }
          );
          this.$toast(data.message);
          // this.getForeignSpeakerList(this.$route.query.projectId);
          location.reload();
        })
        .catch(() => {});
    },
    //推荐理由
    onConfirm(value) {
      this.recommend_id = value;
      this.showPicker = false;
    },
    //微信分享
    async WXShare() {
      let that = this;
      const { data: data } = await that.$http.get("wechat/config", {
        params: {
          url: `${that.ShareDomainName}?projectId=${window.localStorage.getItem(
            "projectId"
          )}`,
        },
      });
      if (data.code != 200) {
        return;
      }
      wx.config(data.data);
      wx.ready(() => {
        let shareData = {
          title: that.shareName,
          desc: "权威、高效、便捷!",
          link: data.data.url,
          imgUrl:
            "https://resources.gongshicloud.com/default%2Fimage%2Fshare_collection_image2.png",
        };
        wx.updateAppMessageShareData(shareData);
        wx.updateTimelineShareData(shareData);
      });
      wx.error((res) => {
        // console.log(res);
        // alert("分享失败");
      });
    },
  },
};
</script>
<style lang="less" scoped>
@import "@/assets/css/980.css";
@import "@/assets/css/768.css";
@import "@/assets/css/550.css";
@import "@/assets/css/410.css";
@import "@/assets/css/320.css";
.foreign-speakers {
  width: 100%;
  // overflow: hidden;
  //暂无数据
  .none {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 15px;
    color: #1e1e1e;
  }

  .collection-bgc {
    position: relative;
    top: 46px;
    width: 100%;
    height: 115px;
  }

  .foreign-speakers-img-log {
    position: relative;
    padding: 0 15px;
    width: 100%;
    height: 52px;
    display: flex;
  }
  //搜索
  .foreign-speakers-seach {
    position: relative;
    width: 100%;
    padding: 0 0 0 15px;
    background-color: #fff;

    display: flex;
    align-items: center;

    .foreign-speakers-seach-input {
      flex: 1;
    }
  }
  .foreign-speakers-baocun {
    position: relative;
    top: 62px;
    left: 0;
    padding-left: 15px;
    /deep/.van-button {
      height: 32px;
    }
  }

  //内容
  .foreign-speakers-contents {
    position: relative;
    top: -19px;
    width: 100%;
    height: calc(100% - 46px);
    background-color: #fff;
    padding-top: 22px;
    border-top-right-radius: 15px;
    border-top-left-radius: 15px;

    .foreign-speakers-contents-ul {
      padding: 0 15px;
      position: relative;
      left: 0;
      padding: 15px 15px 0 15px;

      //奇数
      .foreign-speakers-contents-li:nth-child(odd) {
        //标题
        .foreign-speakers-contents-li-name {
          flex: 1;
          padding: 9px 10px;
          display: flex;
          align-items: center;
          border-radius: 17px;
          font-size: 16px;
          background-color: #d5e3fe;
          color: #437beb;
        }
      }
      //偶数
      .foreign-speakers-contents-li:nth-child(even) {
        //标题
        .foreign-speakers-contents-li-name {
          flex: 1;
          padding: 9px 10px;
          display: flex;
          align-items: center;
          border-radius: 17px;
          font-size: 16px;
          background-color: #f3daf7;
          color: #a739bb;
        }
      }
      //每一个模块
      .foreign-speakers-contents-li {
        // padding: 12px 10px;
        padding: 12px 10px;
        margin-bottom: 15px;
        border-radius: 8px;
        background-color: #ffffff;
        box-shadow: 0px 3px 8px #e0dfff;
        &:last-child {
          margin-bottom: 0;
        }
        //国家
        .foreign-speakers-contents-li-country {
          display: flex;
          align-items: center;
          margin-top: 14px;
          font-size: 15px;
          color: #1e1e1e;
          .country-one {
            display: block;
            width: 10px;
            height: 4px;
            background-color: #4584f2;
            margin-right: 6px;
          }
          .country-two {
            width: 46px;
            font-weight: 600;
          }
          .country-three {
            flex: 1;
          }
        }
        //任职
        .foreign-speakers-contents-li-appointments {
          margin: 18px 0 15px 0;
          font-size: 15px;
          color: #1e1e1e;
          .appointments-dis {
            display: flex;
            align-items: center;
            .appointments-one {
              display: block;
              width: 10px;
              height: 4px;
              background-color: #23a519;
              margin-right: 6px;
            }
            .appointments-two {
              font-weight: 600;
            }
          }
          .appointments-three {
            margin-top: 10px;
            line-height: 25px;
          }
        }
      }
    }
  }
  .recommend-box {
    // margin-top: 5px;
    .recommend-box-on {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 14px;
      .recommend-box-on-text-none {
        font-size: 13px;
        color: #fff;
        background-color: #4059fb;
        // padding: 7px 14px;
        border-radius: 4px;
      }
      .recommend-box-on-text {
        font-size: 13px;
        color: #fff;
        background-color: #4059fb;
        padding: 7px 14px;
        border-radius: 4px;
      }
      .recommend-box-on-box {
        display: flex;
        align-items: center;
        .recommend-box-on-box-zan {
          margin-right: 4px;
          .recommend-box-on-box-zan-img {
            width: 15px;
            height: 13px;
            img {
              width: 100%;
              height: 100%;
            }
          }
        }
        .recommend-box-on-box-num {
          font-size: 13px;
          color: #8e96b4;
          margin-bottom: -4px;
        }
      }
    }
    .recommend-box-liyou {
      margin-bottom: 20px;
      font-size: 15px;
      display: flex;
      .recommend-box-liyou-name {
        width: 84px;
        color: #252525;
        font-weight: 600;
      }
      .recommend-box-liyou-text {
        flex: 1;
        color: #252525;
      }
    }
  }

  .foreign-speakers-contents-li-left-top {
    position: relative;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    color: #212529;
    font-size: 13px;
    background-color: #eeeeee;
    width: 140px;
    padding: 10px;
    top: -13px;
    left: -11px;
    border-top-left-radius: 15px;
    border-bottom-right-radius: 15px;
    .foreign-speakers-contents-li-left-top-left {
      display: flex;
      align-items: center;
      margin-right: 11px;
    }
    .foreign-speakers-contents-li-left-top-right {
      display: flex;
      align-items: center;
      margin-left: 11px;
    }
    .foreign-speakers-contents-li-left-top-left-img {
      width: 16px;
      height: 16px;
      margin-right: 5px;
    }
  }

  .biaoti {
    display: flex;
    //头像
    .foreign-speakers-contents-li-img-box {
      width: 36px;
      margin-right: 8px;
      .foreign-speakers-contents-li-img {
        width: 36px;
        height: 36px;
        overflow: hidden;
        border-radius: 50%;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }

  /deep/.el-collapse {
    border: none !important;
  }
  /deep/ .el-collapse-item__header {
    border-bottom: none !important;
    height: 30px !important;
    line-height: 30px !important;
  }
  /deep/ .el-collapse-item__wrap {
    border-bottom: none !important;
  }
  // 折叠内容
  .foreignSpeakersDetails-contents-bottom {
    position: relative;
    top: -20px;
    padding: 22px 15px 0 15px;
    width: 100%;
    background-color: #fff;

    .none-zhe {
      color: #999999;
      font-size: 13px;
    }

    .foreignSpeakersDetails-contents-bottom-position {
      .top {
        // margin-top: 20px;
        margin-top: 10px;
      }
      .foreignSpeakersDetails-contents-bottom-position-name {
        color: #000;
        font-weight: 600;
        // margin-bottom: 10px;
        margin-bottom: 5px;
      }

      .foreignSpeakersDetails-contents-bottom-position-text {
        color: #999999;
        font-size: 13px;
        line-height: 25px;
        padding-bottom: 10px;
        border-bottom: 1px solid #f5f6f8;
      }
      .foreignSpeakersDetails-contents-bottom-position-Source {
        color: #3479a7;
        font-size: 13px;
        line-height: 25px;
      }
    }
  }
  /deep/ .el-collapse-item__content {
    padding-bottom: 0 !important;
  }

  /deep/ .el-collapse-item__header {
    justify-content: flex-end !important;
    align-items: center !important;
    font-size: 12px !important;
  }
  /deep/ .el-collapse-item__arrow {
    margin: 0 8px 0 5px !important;
    font-size: 14px !important;
  }
}
</style>
