<template>
  <div class="collection">
    <van-nav-bar
      title="我的合集"
      fixed
      left-arrow
      @click-left="$router.push('my')"
    />
    <div class="collection-po">
      <div class="collection-bgc"><img src="@/assets/image/my/bgc.png" /></div>
      <div class="collection-box">
        <div class="collection-box-bgc">
          <img src="@/assets/image/my/wai.png" />
        </div>
        <van-empty
          style="margin: 20% 0"
          description="暂无数据"
          v-if="aprenderObject.list.length == 0"
        />
        <ul class="collection-ul" v-else>
          <li
            class="collection-li"
            v-for="(item, index) in aprenderObject.list"
            :key="index"
            @click="collectionView(item)"
          >
            <div class="collection-li-left">
              <div class="collection-li-left-img">
                <img src="@/assets/image/my/蒙版组 228@2x.png" />
              </div>
              <div class="collection-li-left-name">{{ item.name }}</div>
              <div class="collection-li-left-num">
                <div class="collection-li-left-num-box">
                  <van-icon name="description" />
                  <span style="font-size: 13px; margin-left: 3px">
                    {{ item.count }}
                  </span>
                </div>
              </div>
            </div>
            <div class="collection-li-right">
              <!-- <div
                class="collection-li-right-box"
                style="margin-bottom: 10px"
                @click.stop="shareClick(item)"
              >
                <van-icon name="share-o" />
                <div class="collection-li-right-box-name">分享</div>
              </div> -->
              <div
                class="collection-li-right-box"
                @click.stop="collectionDelete(item)"
              >
                <van-icon name="delete-o" />
                <div class="collection-li-right-box-name">删除</div>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
import wx from "weixin-js-sdk";
export default {
  name: "collection",
  data() {
    return {
      centerDialogVisibleWX: false, //微信弹窗
      centerDialogVisible: false, //弹窗
      reproductionSuccess: false,
      CollectionTitle: "",
      dialogItme: {}, //pc
      dialogItmeYD: {}, //移动
      total: 10, //总条目数
      pagesize: 12, //每页显示条目个数
      currentPage: 1, //当前页数
      aprenderObject: { list: [] },
      ShareDomainName: "https://speaker.mobile.netloop.cn/collection",
      userId: "",
      default: {
        share_collection_id: "",
        userId: "",
      },
    };
  },
  created() {
    this.getCollectionList(
      this.default.share_collection_id,
      this.default.userId
    );
  },
  watch: {
    $route: {
      handler: function (to, from) {
        if (
          to.query.projectId != "" &&
          to.query.projectId != undefined &&
          to.query.projectId != null
        ) {
          this.getCollectionList(to.query.projectId, to.query.userId);
        }
      },
      // 深度观察监听
      deep: true,
      immediate: true,
    },
  },
  methods: {
    //获取列表
    async getCollectionList(share_collection_id, userId) {
      const { data: data } = await this.$http.post("speaker/collection/list", {
        share_collection_id,
        userId,
        page: this.currentPage,
        limit: this.pagesize,
      });
      this.aprenderObject = data.data;
      // this.WXShare();
    },
    //查看详情
    collectionView(item) {
      this.$router.push({
        path: "/foreignSpeakers",
        query: {
          projectId: item.id,
          // name: item.name,
        },
      });
    },
    //分享
    async shareClick(item) {
      localStorage.setItem("projectId", item.id);
      this.userId = item.user_id;
      // this.WXShare();
    },
    //微信分享
    async WXShare() {
      let that = this;
      const { data: data } = await that.$http.get("wechat/config", {
        params: {
          url: `${that.ShareDomainName}`,
        },
      });
      if (data.code != 200) {
        return;
      }
      wx.config(data.data);
      // appId: data.data.appId;
      // beta: false,
      // debug: true,
      // nonceStr:data.data.nonceStr,
      // jsApiList:["updateAppMessageShareData","updateTimelineShareData"]
      // openTagList: data.data.openTagList,
      // signature:data.data.signature,
      // timestamp:data.data.timestamp,
      // url:data.data.url,
      wx.ready(() => {
        let shareData = {
          title: "测试测试测试测试测试",
          desc: "测试测试测试测试测试",
          link: data.data.url,
          imgUrl:
            "https://resources.gongshicloud.com/default%2Fimage%2Fshare_collection_image2.png",
        };
        wx.updateAppMessageShareData(shareData);
        wx.updateTimelineShareData(shareData);
      });
      wx.error((res) => {
        console.log("错误信息", res);
        // alert("分享失败");
        // alert(res);
        // alert("分享失败");
      });
    },
    //删除
    collectionDelete(item) {
      this.$dialog
        .confirm({
          title: "提示",
          message:
            "分类删除后，该分类下的所有信息将会删除，您确定要继续删除吗？",
        })
        .then(async () => {
          const { data: data } = await this.$http.post(
            "speaker/collection/delete",
            {
              id: item.id,
            }
          );
          this.$toast(data.message);
          // this.getCollectionList();
          location.reload();
        })
        .catch(() => {});
    },
    //返回
    backProject() {
      this.$router.back();
    },
    //点击每一页
    currentchange(e) {
      this.currentPage = e;
      setTimeout(() => {
        this.getCollectionList();
      }, 500);
      this.$Spin.show();
    },
    //上一页
    prevClick(e) {
      this.currentPage = e;
      setTimeout(() => {
        this.getCollectionList();
      }, 500);
      this.$Spin.show();
    },
    //下一页
    nextClick(e) {
      this.currentPage = e;
      setTimeout(() => {
        this.getCollectionList();
      }, 500);
      this.$Spin.show();
    },
  },
};
</script>
<style lang="less" scoped>
.collection {
  position: relative;
  height: 100vh;
  //  height: calc(100vh -67px);
  .collection-po {
    position: relative;
    top: 46px;
    height: calc(100% - 46px);
  }
  .collection-bgc {
    position: relative;
    width: 100%;
    height: 115px;
  }
  .collection-box {
    position: relative;
    top: -67px;
    left: 0;
    background-color: #fff;
    border-top-right-radius: 15px;
    border-top-left-radius: 15px;
    // height: 400px;
    // padding: 22px 15px;
    padding: 22px 15px 0 15px;
    .collection-box-bgc {
      width: 100%;
      height: 52px;
    }

    //列表
    .collection-ul {
      padding-bottom: 20px;
      .collection-li {
        border-radius: 6px;
        padding: 17px 17px 36px 17px;
        margin-top: 16px;
        display: flex;
        justify-content: space-between;
        background-image: url("@/assets/image/my/mengbantu.png");
        background-size: 346px 189px;

        //左侧
        .collection-li-left {
          .collection-li-left-img {
            width: 121px;
            height: 39px;
            margin-bottom: 24px;
          }
          .collection-li-left-name {
            color: #063b6b;
            font-size: 18px;
            font-weight: 600;
            margin-bottom: 22px;
          }
          .collection-li-left-num {
            display: flex;
            .collection-li-left-num-box {
              padding: 3px 8px;
              border-radius: 5px;
              background-color: #063b6b;
              color: #fff;
            }
          }
        }
        //右侧
        .collection-li-right {
          .collection-li-right-box {
            display: flex;
            align-items: center;
            color: #063b6b;
            font-weight: 600;
            .collection-li-right-box-name {
              margin-left: 4px;
              font-size: 13px;
            }
          }
        }
      }
    }
  }
}
</style>
