import axios from 'axios'
import router from '@/router'; 
import { Toast } from 'vant';
const devBaseUrl = '/apis/api/'; //开发环境
const proBaseUrl = 'https://api.speaker.netloop.cn/api/'; //生产环境   
// const proBaseUrl = 'http://test.gongshicloud.com/api/'; //开发环境      
let url;
process.env.NODE_ENV === 'development' ? url = devBaseUrl : url = proBaseUrl;
// 创建axios实例 
const service = axios.create({
    baseURL: url,
    timeout: 10000 
})

service.interceptors.request.use(config => {
    config.headers.Authorization = `Bearer ${localStorage.getItem("token")}`
    return config
}) 

service.interceptors.response.use(response => {
      if (response.data.code === 200) {
      return Promise.resolve(response)  
        //约定code 401 token 过期 
      } else if (response.data.code === 401) {   
        router.push('/login') 
        // Toast(response.data.message);   
        return Promise.reject(response.data)    
      } else {
        Toast(response.data.message);  
            return Promise.reject(response.data)
      } 
})  
export default service;